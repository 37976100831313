import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getBrands } from "../api/apiBrands";
import { PAGE_SIZE } from "../utils/constants";
import i18next from "i18next";

export function useBrands(page) {
    const queryClient = useQueryClient();

    const {
        isLoading,
        data: brands,
        error,
    } = useQuery({
        queryKey: ["brands", page, i18next.language],
        queryFn: () => getBrands(page),
        retry: false,
    });

    // PRE-FETCHING
    const pageCount = Math.ceil(brands?.length / PAGE_SIZE);

    if (page < pageCount)
        queryClient.prefetchQuery({
            queryKey: ["brands", page + 1],
            queryFn: () => getBrands({ page: page + 1 }),
        });

    if (page > 1)
        queryClient.prefetchQuery({
            queryKey: ["brands", page - 1],
            queryFn: () => getBrands({ page: page - 1 }),
        });
    return { isLoading, error, brands };
}
