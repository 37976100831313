import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getClients } from "../api/apiClients";
import { PAGE_SIZE } from "../utils/constants";
import i18next from "i18next";

export function useClients(page) {
    const queryClient = useQueryClient();

    const {
        isLoading,
        data: clients,
        error,
    } = useQuery({
        queryKey: ["clients", page, i18next.language],
        queryFn: () => getClients(page),
        retry: false,
    });

    // PRE-FETCHING
    const pageCount = Math.ceil(clients?.length / PAGE_SIZE);

    if (page < pageCount)
        queryClient.prefetchQuery({
            queryKey: ["clients", page + 1],
            queryFn: () => getClients({ page: page + 1 }),
        });

    if (page > 1)
        queryClient.prefetchQuery({
            queryKey: ["clients", page - 1],
            queryFn: () => getClients({ page: page - 1 }),
        });
    return { isLoading, error, clients };
}
