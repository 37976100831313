import { Grid } from '@mui/material'
import React from 'react'
import styled from "styled-components";

const Empty = styled.p` 
      font-size: 1.6rem;
      font-weight: 500;
      text-align: center;
      margin: 2.4rem; 
    `;

const GridItems = ({ data, slices, render }) => {
    // Sort the data by 'order' if it exists
    const sortedData = data?.sort((a, b) => {
        return a?.order - b?.order;
    });
    
    if (!data.length) return <Empty>No data to show at the moment</Empty>;
    return (
        <>
            <Grid container spacing={2} justifyContent={'space-evenly'}>
                {slices ? sortedData?.slice(0, slices)?.map(render) : sortedData?.map(render)}
            </Grid>
        </>
    )
}

export default GridItems