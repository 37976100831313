import { CardContent, CardMedia, Typography, Card as CardBrand } from '@mui/material' 
import React from 'react'

const Card = ({ brand }) => {
    const { name, image } = brand || {};
    return (
        <CardBrand sx={{ my: 1, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.20)", cursor: 'pointer', margin:'10px auto', height:'100%', display:'flex', flexDirection:'column', justifyContent:'space-between' }}  
            className='card reset'>
            <CardMedia
                sx={{ height: 125, backgroundSize:'contain' }}
                image={image}
                className='flash reset'
                title="green iguana"
            />
            <CardContent>
                <Typography gutterBottom variant="h6" component="div" color={'primary.main'} sx={{ textTransform: 'uppercase', textAlign:'center' }} >
                    {name}
                </Typography>
            </CardContent>
        </CardBrand>
    )
}

export default Card
