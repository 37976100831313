import { useQuery } from "@tanstack/react-query";
import { getGreenSection } from "../api/apiGreenSection";
import i18next from "i18next";

export function useGreenSection() {

    const {
        isLoading,
        data: greenSection,
        error,
    } = useQuery({
        queryKey: ["greenSection", i18next.language],
        queryFn: () => getGreenSection(),
    retry: false,
    });

    return { isLoading, error, greenSection };
}
