import React, { useState } from 'react'
import Header from '../components/global/headers/Header'
import WrapperSection from '../components/global/WrapperSection'
import Card from '../components/Clients/cards/Card'
import GridItems from '../components/global/GridItems'
import { Grid } from '@mui/material'
import LoadingPage from '../components/global/LoadingPage'
import { useClients } from '../hooks/useClients'
import CustomPagination from '../components/global/CustomPagination'

const Clients = () => {
    const [page, setPage] = useState(1);

    const { clients: clientsData, isLoading } = useClients(page)
    if (isLoading) return <LoadingPage />
    const { data } = clientsData

    return (
        <>
            <Header page_name={clientsData?.client_title} cover={clientsData?.client_cover} />
            <WrapperSection title={clientsData?.client_title}>
                {data && <GridItems data={data}
                    render={(client) => <Grid item md={2} xs={6} key={client?.id} ><Card client={client} /></Grid>} />}
                {
                    clientsData?.meta?.last_page > 1 &&
                    <CustomPagination
                        setCurrentPage={setPage}
                        count={clientsData?.meta?.last_page}
                        currentPage={page}
                    />
                }
            </WrapperSection>

        </>
    )
}

export default Clients