import React from 'react'
import GridItems from '../global/GridItems'
import { Grid } from '@mui/material'
import LoadingPage from '../global/LoadingPage'
import { useBrands } from '../../hooks/useBrands'
import WrapperSection from '../global/WrapperSection'
import Card from './cards/Card'

const ListBrand = ({ page, children }) => {

    const { brands: brandsData, isLoading } = useBrands(page)
    if (isLoading) return <LoadingPage />
    const { data } = brandsData

    return (
        <div>
            <WrapperSection title={brandsData?.brand_title}>
                {data && <GridItems data={data}
                    render={(brand) => <Grid item md={3} xs={12} key={brand?.id} ><Card brand={brand} /></Grid>} />}
                {children}
            </WrapperSection>
        </div>
    )
}

export default ListBrand