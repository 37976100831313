import React from 'react'
import Header from '../components/global/headers/Header'
import WrapperSection from '../components/global/WrapperSection'
import { useGreenSection } from '../hooks/useGreenSection'
import LoadingPage from '../components/global/LoadingPage'
const GreenInitiative = () => {

    const { greenSection: greenSectionData, isLoading } = useGreenSection()
    if (isLoading) return <LoadingPage />
    const { data } = greenSectionData
    return (
        <>
            <Header page_name={data?.page_name} cover={data?.cover_image}/> 
            <WrapperSection title={data?.main_title} description={data?.description} />
            <iframe width="100%" height="600" src={data?.video_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </>
    )
}

export default GreenInitiative