import React, { useState } from 'react'
import Header from '../components/global/headers/Header'
// import WrapperSection from '../components/global/WrapperSection'
// import Card from '../components/brands/cards/Card'
// import GridItems from '../components/global/GridItems'
// import { Grid } from '@mui/material'
import LoadingPage from '../components/global/LoadingPage'
import { useBrands } from '../hooks/useBrands'
import CustomPagination from '../components/global/CustomPagination'
import ListBrand from '../components/brands/ListBrand'

const Brands = () => {
    const [page, setPage] = useState(1);

    const { brands: brandsData, isLoading } = useBrands(page)
    if (isLoading) return <LoadingPage />
    const { data } = brandsData

    return (
        <>
            <Header page_name={brandsData?.brand_title} cover={brandsData?.brand_cover} />
            <ListBrand page={page}>
                {
                    brandsData?.meta?.last_page > 1 &&
                    <CustomPagination
                        setCurrentPage={setPage}
                        count={brandsData?.meta?.last_page}
                        currentPage={page}
                    />
                }
            </ListBrand>
            {/* <WrapperSection title={brandsData?.brand_title}>
                {data && <GridItems data={data}
                    render={(brand) => <Grid item md={3} xs={12} key={brand?.id} ><Card brand={brand} /></Grid>} />}
                
            </WrapperSection> */}

        </>
    )
}

export default Brands